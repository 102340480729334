import React from "react"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import PostList from '../components/postList'
import { Link } from "gatsby"
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import styles from '../styles/postsPage.module.css'


const postsPage = ({data, pageContext, location}) => {
    const currentPage = pageContext.currentPage
    const previousPageLink = currentPage !=2? "/page/" + (currentPage - 1): "/"
    const nextPageLink = "/page/" + (currentPage + 1)
    return(
        <div>
            <SEO title={"Tasty Appetite - Page " + currentPage} canonicalPath={location.pathname}/>
            <PostList data={data.allStrapiPosts.edges}/>
            <div className={styles.pageLinkContainer}>
              <div className={styles.prevPage}>
                <Link to={previousPageLink}>
                  <AiOutlineLeft style={{paddingTop: '5px'}} size='1em'/><span>Previous page</span>
                </Link>
              </div>
              { pageContext.numPages !== currentPage?
                  <div className={styles.nextPage}>
                    <Link to={nextPageLink}>
                      <span>Next page</span><AiOutlineRight style={{paddingTop: '5px'}} size='1em'/>
                    </Link>
                  </div>:
                    ''
              }   
            </div>
            
        </div>
    )
}

export const query = graphql`
  query postPaginatedQuery($skip: Int!, $limit: Int!) {
    allStrapiPosts(
      sort: { fields: [created_at], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {  
          id
          title
          description
          websiteURL
          created_at
          youtubeURL
          recipe_category {
            name
          }
          recipe_cuisine {
            name
          }
          featureImage {
            childImageSharp {
              fluid(cropFocus: ENTROPY){
                src
                srcSet
                presentationWidth
                presentationHeight
                base64
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`

export default postsPage
